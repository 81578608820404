import { lazy } from 'react'
import { IRoute } from './types'
import { ReactComponent as UserSvg } from '@/assets/svg/user.svg'
import { PERMISSIONS } from '@/constants'
const AgentManagement = lazy(() => import('@/pages/AgentManagement'))

const router: IRoute[] = [
  {
    label: 'Agent Management',
    path: '/agent-management',
    icon: <UserSvg />,
    element: <AgentManagement />,
    permissions: [PERMISSIONS.AGENT_MANAGEMENT_PERFORMANCE_READ]
  }
]

export default router
