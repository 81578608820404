import { ApmsSdk } from '@shopee/apms-javascript-sdk'
import ExceptionPlugin from '@shopee/apms-sdk-plugin-exception'
import { currentRegion } from '@/constants/region'
import { parseCookie } from './cookie'
const cookie = parseCookie()

const APP_ID = '109'
const SECRET_KEY = '12dd5acab7044fa0937cb06c07328455'
const APP_ID_NONLIVE = '110'
const SECRET_KEY_NONLIVE = '2a604f01c9674cfc818edaf162ede5b2'

const sdk = new ApmsSdk({
  // apms app id, required
  de_app_id: DEPLOY_ENV === 'live' ? APP_ID : APP_ID_NONLIVE,
  // api key, required
  secret_key: DEPLOY_ENV === 'live' ? SECRET_KEY : SECRET_KEY_NONLIVE,
  // required
  js_bundle_name: 'tns-task-center',
  // required
  js_bundle_version: '0.0.1',
  // reporting region, required
  region: currentRegion,
  // required
  js_build_id: '0.0.1',
  // reporting environment, required enum: 'test' | 'staging' | 'live' | 'liveish' | 'mock' | 'uat'
  environment: 'live',
  // report interval(ms), dfault - 10000ms
  interval: 10000,
  // user id
  user_id: cookie.user_id,
  // user email
  user_email: cookie.email,
  // print log? | default - false
  logger: true
  // user can provide their own device id as fingerprint, optional
  // fingerprint: "1234567890"
}) // initializing sdk
sdk.addEntryTag('email', cookie.email)
sdk.use(
  new ExceptionPlugin({
    beforeAddData(data) {
      if (
        data.js_error_message?.match(
          /play\(\)|Network ?Error|timeout|Request aborted|ResizeObserver|error loading dynamically imported module|Script error/
        )
      ) {
        return false
      }
      return data
    }
  })
)
