export const DOMAIN_SUFFIX_REGION: Record<string, string> = {
  'co.id': 'ID',
  'com.br': 'BR',
  'com.my': 'MY',
  ph: 'PH',
  sg: 'SG',
  'co.th': 'TH',
  tw: 'TW',
  vn: 'VN',
  'com.mx': 'MX',
  'com.co': 'CO',
  cl: 'CL'
}

export const REGION_DOMAIN_SUFFIX = Object.keys(DOMAIN_SUFFIX_REGION).reduce((res: Record<string, string>, item) => {
  res[DOMAIN_SUFFIX_REGION[item]] = item
  return res
}, {})

export const REGION_SELECT_LIST = Object.keys(DOMAIN_SUFFIX_REGION).map((item) => {
  return {
    label: DOMAIN_SUFFIX_REGION[item],
    value: item
  }
})

export const currentRegion: string = (() => {
  const region = 'id'
  const domain = document.domain
  const some = Object.keys(DOMAIN_SUFFIX_REGION).find((item) => domain.endsWith(item))
  if (some) {
    return DOMAIN_SUFFIX_REGION[some].toUpperCase()
  }
  return region.toUpperCase()
})()
