export const ORIGIN = window.location.origin
export const TASK_API_BASE_URL = ORIGIN + '/task/api'
export const QUEUE_API_BASE_URL = ORIGIN + '/queue/api'

export const TEMPLATE_API_BASE_URL = ORIGIN + '/template/api'

export enum RESPOND_CODES {
  RESPONSE_SUCC = 0,
  ERROR_AGENT_NOT_FOUND = 131800018
}

export const LIST_PAGE_SIZE = 10

export const errorCodeMap: { [key: number]: string } = {}

/**
 * ORIGIN:
    测试：https://sv-qc.test.shopee.co.id
    UAT：https://sv-qc.uat.shopee.co.id
    LIVE：https://sv-qc.live.shopee.co.id
 */

export const COUNTRY_DOMAIN_SUFFIX = {
  ID: 'co.id'
}

// 下面 IMAGE_HOST_MAP 从 RN 的 @shopee-rn/app-env 复制而来
export const IMAGE_HOST_MAP = {
  SG: 'cf.shopee.sg',
  TW: 'cf.shopee.tw',
  MY: 'cf.shopee.com.my',
  PH: 'cf.shopee.ph',
  TH: 'cf.shopee.co.th',
  ID: 'cf.shopee.co.id',
  VN: 'cf.shopee.vn',
  BR: 'cf.shopee.com.br',
  MX: 'cf.shopee.com.mx',
  CO: 'cf.shopee.com.co',
  CL: 'cf.shopee.cl'
}

export const IMAGE_HOST =
  'https://' +
  (IMAGE_HOST_MAP as any)[
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion, @typescript-eslint/no-unused-vars
    Object.entries(COUNTRY_DOMAIN_SUFFIX).find(([country, suffix]) => {
      return new RegExp(`${suffix}$`).test(suffix)
    })![0]
  ] +
  '/file/'
