import React from 'react'
import { IRoute } from './types'
const MMCPlayer = React.lazy(() => import('@/pages/mmcPlayer'))

const router: IRoute = {
  label: '',
  path: '/mmcPlayer',
  notUseForMenu: true,
  element: <MMCPlayer />
}

export default router
