import React from 'react'
import { IRoute } from './types'
const ExportRecord = React.lazy(() => import('@/pages/ExportRecord'))
const UploadRecord = React.lazy(() => import('@/pages/UploadRecord'))

export const exportRecord: IRoute = {
  label: '',
  path: '/export-record/:type',
  notUseForMenu: true,
  element: <ExportRecord />,
  jumpAfterSwitchBiz: '/task/task-list'
}

export const uploadRecord: IRoute = {
  label: '',
  path: '/upload-record/:type',
  notUseForMenu: true,
  element: <UploadRecord />,
  jumpAfterSwitchBiz: '/task/task-list'
}
