import React, { useCallback } from 'react'
import { ReactComponent as LogoSvg } from '@/assets/svg/logo.svg'
import { useNavigate } from 'react-router-dom'
function Logo() {
  const navigate = useNavigate()
  const handleClick = useCallback(() => {
    navigate('/task-list')
  }, [navigate])
  return (
    <div className='tns-task-center-logo-wrapper' onClick={handleClick}>
      <LogoSvg className='logo' />
      Task Center
    </div>
  )
}

export default Logo
