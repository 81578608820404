import React, { useCallback } from 'react'
import { Select } from '@shopee/solar-design'
import { commonConfig } from '@/stores'
import { observer } from 'mobx-react-lite'
import { useCurrentRouter } from '@/hooks/router'
import { IRoute } from '@/routes'

const ProductLine: React.FunctionComponent = () => {
  const { businessInfo, businessId } = commonConfig
  const options = Object.entries(businessInfo).map(([key, value]) => ({
    value: +key,
    label: value
  }))
  const currentRouters = useCurrentRouter()

  const handleChange = useCallback(
    (id: number) => {
      const route = currentRouters[currentRouters.length - 1]?.route as IRoute
      commonConfig.setBusinessId(id)
      if (route.jumpAfterSwitchBiz) {
        window.location.replace(route.jumpAfterSwitchBiz)
      } else {
        window.location.reload()
      }
    },
    [currentRouters]
  )

  return (
    <Select
      options={options}
      defaultValue={businessId}
      onChange={handleChange}
      className='task-center-product-line-select'
    />
  )
}

export default observer(ProductLine)
