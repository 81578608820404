import React from 'react'
import { IRoute } from './types'
const TaskInfo = React.lazy(() => import('@/pages/TaskInfo'))
const AbandonRecord = React.lazy(() => import('@/pages/TaskInfo/components/TaskPool/components/AbandonRecord'))

const routers: IRoute[] = [
  {
    label: 'Dashboard',
    path: '/task-info/dashboard',
    notUseForMenu: true,
    element: <TaskInfo />,
    jumpAfterSwitchBiz: '/task/task-list'
  },
  {
    label: 'Task Pool',
    path: '/task-info/taskpool',
    notUseForMenu: true,
    element: <TaskInfo />,
    jumpAfterSwitchBiz: '/task/task-list'
  },
  {
    label: 'Task Pool Abandon Task',
    path: '/task-info/abandon-record',
    notUseForMenu: true,
    element: <AbandonRecord />,
    jumpAfterSwitchBiz: '/task/task-list'
  }
]
export default routers
