import React from 'react'
import { IRoute } from './types'
const PolciyViewMode = React.lazy(() => import('@/pages/PolicyViewMode'))

const polciyViewMode: IRoute = {
  label: '',
  path: '/policy-view-mode',
  notUseForMenu: true,
  hiddenMenu: true,
  element: <PolciyViewMode />,
  jumpAfterSwitchBiz: '/task/task-list'
}

export default polciyViewMode
