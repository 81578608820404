import { useCallback } from 'react'
import { UserOutlined } from '@shopee/solar-icons'
import { Layout, Popover, Avatar, Menu } from '@shopee/solar-design'
import commonConfig from '@/stores/config'
import { IRegionInfo } from '@/types/config'
import Logo from './Logo'
import { loginOut } from '@/network/config'
import ProductLine from './ProductLine'
import { useCurrentRouter } from '@/hooks/router'
import { IRoute } from '@/routes'
import Timezone from './Timezone'

const { Header } = Layout

export default function AppHeader() {
  // 退出登录事件
  const handleLoginOut = useCallback(async () => {
    try {
      await loginOut()
      window.location.reload()
    } catch (err) {
      console.log({ err })
    }
  }, [])

  const { regionList } = commonConfig

  const currentRouters = useCurrentRouter()

  const switchRegion = (value: IRegionInfo) => {
    const { name } = value

    if (name.toLowerCase() !== commonConfig.region) {
      const route = currentRouters[currentRouters.length - 1]?.route as IRoute
      const { domain } = value
      const origin = new URL(domain).origin
      const jumpUrl = route.jumpAfterSwitchBiz ? origin + route.jumpAfterSwitchBiz : origin + '/task'
      const url = new RegExp(/^(https?:\/\/)/).test(jumpUrl) ? jumpUrl : '//' + jumpUrl

      window.location.href = url
    }
  }

  const popover = (
    <div>
      <Menu mode='vertical'>
        <Menu.SubMenu title='Region' popupClassName='tns-header-logo-popover-submenu'>
          {(regionList ?? []).map((region) => (
            <Menu.Item key={region.name} onClick={() => switchRegion(region)}>
              {region?.name}
            </Menu.Item>
          ))}
        </Menu.SubMenu>
        <Menu.Item onClick={handleLoginOut} className='logout'>
          Sign out
        </Menu.Item>
      </Menu>
    </div>
  )

  return (
    <Header className='tns-header'>
      <Logo />
      <div>
        <ProductLine />
        <Timezone />
        <Popover
          content={popover}
          placement='bottomRight'
          trigger='click'
          overlayClassName='tns-header-logo-popover-overlay'
        >
          <Avatar className='tns-header-avatar' src={commonConfig.photo} size={36} icon={<UserOutlined />} />
        </Popover>
      </div>
    </Header>
  )
}
