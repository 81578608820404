import React from 'react'
import { Navigate } from 'react-router-dom'
import tasks from './tasks'
import overallDashboard from './overallDashboard'
import queueManagement from './queueManagement'
import taskInfo from './taskInfo'
import { exportRecord, uploadRecord } from './exportRecord'
import templateConfig from './templateConfig'
import agentManagement from './agentManagement'
import policyViewMode from './policyViewMode'
import liveCheck from './liveCheck'
import demo from './demo'
import MMCPlayer from './mmcPlayer'
import { IRoute } from './types'

const routes: IRoute[] = [
  ...tasks,
  ...taskInfo,
  ...overallDashboard,
  ...queueManagement,
  ...agentManagement,
  exportRecord,
  uploadRecord,
  policyViewMode,
  ...templateConfig,
  liveCheck,
  demo,
  MMCPlayer,
  {
    label: '',
    path: '*',
    element: <Navigate to='/task-list' />
  }
]

if (DEPLOY_ENV === 'development') {
  routes.splice(routes.length - 2, 0, demo)
}

export default routes

export type { IRoute }
