import { useCallback, useMemo, useState } from 'react'
import { Menu } from '@shopee/solar-design'
import routes, { IRoute } from '@/routes'
import { hasRouterPermiss, routePath } from '@/routes/utils'
import { useNavigate } from 'react-router-dom'
import user from '@/stores/config'
import { toJS } from 'mobx'
import Sider from '@shopee/solar-design/lib/layout/Sider'
import { useCurrentRouter } from '@/hooks/router'
import classNames from 'classnames'
import { MenuFoldOutlined, MenuUnfoldOutlined } from '@shopee/solar-icons'

const { SubMenu, Item } = Menu

export default function AppMenu() {
  const navigate = useNavigate()
  const myPermissions = toJS(user.hasPermissions)
  const currentRouters = useCurrentRouter()
  const isHiddenSiderBar = useMemo(() => {
    if (!currentRouters || !currentRouters.length) {
      return false
    } else if ((currentRouters[currentRouters.length - 1].route as unknown as IRoute).hiddenMenu) {
      return true
    }
    return false
  }, [currentRouters])
  const initMenuItems = useCallback(
    (routesArray: IRoute[], parentPath = '') => {
      return routesArray
        .map((route) => {
          const { icon, label, path, children, notUseForMenu } = route
          if (!notUseForMenu && hasRouterPermiss(route, myPermissions)) {
            if (children) {
              return (
                <SubMenu
                  key={parentPath + routePath(path)}
                  title={
                    <>
                      {/* {icon ? icon : null} */}
                      <span>{label}</span>
                    </>
                  }
                  icon={icon}
                >
                  {initMenuItems(children, parentPath + routePath(path))}
                </SubMenu>
              )
            } else {
              return (
                <Item
                  key={parentPath + routePath(path)}
                  title={
                    <>
                      {/* {icon ? icon : null} */}
                      <span>{label}</span>
                    </>
                  }
                  icon={icon}
                >
                  <span>{label}</span>
                </Item>
              )
            }
          } else {
            return null
          }
        })
        .filter((i) => i)
    },
    [myPermissions]
  )
  const openKeys = useMemo(() => {
    if (currentRouters && currentRouters.length > 1) {
      return currentRouters.slice(0, currentRouters.length - 1).map((item) => item.pathname)
    }
    return []
  }, [currentRouters])

  const defaultSelectedKeys = useMemo(() => {
    if (currentRouters) {
      return currentRouters.slice(-1).map((item) => item.pathname)
    }
    return []
  }, [currentRouters])

  // 控制Sider开关
  const [isCollapsed, setIsCollapsed] = useState(false)

  const onCollapse = useCallback(() => {
    setIsCollapsed((collapsed) => !collapsed)
  }, [])

  // 点击菜单导航事件
  const navTo = useCallback(
    (route: any) => {
      navigate(route.key)
    },
    [navigate]
  )
  const trigger = useMemo(() => {
    return isCollapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />
  }, [isCollapsed])

  const menus = useMemo(() => initMenuItems(routes.slice(0, routes.length - 1)), [initMenuItems])
  if (isHiddenSiderBar) {
    return null
  }
  return (
    <Sider
      width={240}
      theme='light'
      collapsible
      trigger={trigger}
      collapsed={isCollapsed}
      onCollapse={onCollapse}
      className='tns-task-center-sider'
    >
      <Menu
        onClick={navTo}
        mode='inline'
        inlineCollapsed={isCollapsed}
        defaultOpenKeys={openKeys}
        defaultSelectedKeys={defaultSelectedKeys}
        className={classNames('tns-task-center-sider-menu', isCollapsed && 'ant-menu-inline-collapsed')}
      >
        {menus}
      </Menu>
    </Sider>
  )
}
