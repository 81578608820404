import { IConfig } from '@/types/config'
import api from './http'
import to from 'await-to-js'

export const loginOut = () => {
  return api.post({
    url: '/portal/logout'
  })
}

export const getUserPermission = async () => {
  const res: any = await api.get({ url: '/perms' })
  return res?.data?.perms ? res.data.perms : []
}

export const getConfig = async () => {
  const [err, res] = await to(api.get({ url: '/portal/config' }))
  if (err) {
    throw err
  }
  return res.data as IConfig
}
