import { IConfig, ISoupPermission, ITimezoneItem, IRegionInfo, ITagInfo } from '@/types/config'
import { getConfig } from '@/network/config'
import { action, autorun, makeAutoObservable, runInAction } from 'mobx'
import dayjs from 'dayjs'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import { taskService, queueService, reportService } from '@/network/http'
import { getEnvByHost, Env } from '@/utils/url'
import { currentRegion } from '@/constants/region'
import to from 'await-to-js'

dayjs.extend(timezone)
dayjs.extend(utc)

const defaultTimezoneList = [
  {
    name: 'JKT UTC+7',
    value: 'Asia/Jakarta'
  },
  {
    name: 'BJ UTC+8',
    value: 'Asia/Shanghai'
  }
]

const env = getEnvByHost()

const defaultRegionList = [
  {
    name: 'ID',
    domain: `tns.sv${env === Env.Test || env === Env.Uat ? `.${env}` : ''}.shopee.co.id`,
    timezone: {
      name: 'JKT UTC+7',
      value: 'Asia/Jakarta'
    }
  }
]

// 通用配置接口，前置接口将在layout中运行，page无需关心，如果page中要用其中属性可以直接引入使用
class CommonConfig {
  email = ''
  photo = ''
  username = ''
  hasPermissions: Record<string, boolean> = {}
  // 用户是否有准入权限
  soupPermission: ISoupPermission = {
    status: false,
    soupUrl: ''
  }
  isLoading = false
  timezoneList: ITimezoneItem[] = defaultTimezoneList
  tagMap: Map<string, ITagInfo> = new Map()
  regionList: IRegionInfo[] = defaultRegionList
  // 先直接取本地浏览器的时区
  currentTimezone = ''
  region = currentRegion.toLowerCase()
  userId = 0
  businessInfo: Record<number, string> = {}
  // 获取本地存储businessId
  businessId = Number(localStorage.getItem('businessId') || -1)

  constructor() {
    makeAutoObservable(this, {
      getCommonConfig: action.bound,
      setCurrentTimezone: action.bound,
      setBusinessId: action.bound
    })
    // 优先中本地存储取
    const zone = localStorage.getItem('timezone') || dayjs.tz.guess()
    this.setCurrentTimezone(zone)
  }

  async getCommonConfig() {
    this.isLoading = true
    const [err, result] = await to(Promise.all([getConfig()]))
    if (err) {
      return
    }
    runInAction(() => {
      const config: IConfig = result[0]
      try {
        this.timezoneList = typeof config.timezone === 'string' ? JSON.parse(config.timezone) : config.timezone
        const tagList: ITagInfo[] =
          (typeof config?.tagConfig === 'string' ? JSON.parse(config.tagConfig) : config.tagConfig) ?? []
        tagList.forEach((element) => {
          const { id } = element
          this.tagMap.set('' + id, element)
        })
        this.regionList =
          typeof config?.regionList === 'string'
            ? JSON.parse(config.regionList)
            : config.regionList || defaultRegionList
      } catch (error) {
        console.error(error)
      }
      if (config?.region) {
        this.region = config?.region.toLowerCase()
      }
      this.email = config.email || ''
      this.photo = config.avatar || ''
      this.username = config.username || ''
      this.userId = config.userId || 0
      this.isLoading = false
      this.hasPermissions = config.hasPermissions || {}
      this.soupPermission = config.permission ? config.permission : { status: false, soupUrl: '' }

      // 该地区对应的时区
      const regionZone = this.regionList.find((item) => item.name.toLowerCase() === config?.region)?.timezone?.value
      // 时区优先级 本地 > 域名地区对应时区 > 本地guess
      const computedZone = localStorage.getItem('timezone') || regionZone || dayjs.tz.guess()

      // 接口返回的timezone和本地的timezone不同，则重置本地的
      if (!this.timezoneList.some((item) => item.value === computedZone)) {
        this.setCurrentTimezone(regionZone ?? this.timezoneList[0].value)
      } else if (computedZone !== this.currentTimezone) {
        // 若本地未设置时区，时区为域名对应的时区
        this.setCurrentTimezone(computedZone)
      }
      this.businessInfo = config.businessInfo || {}
      if (this.businessId === -1) {
        this.setBusinessId(Number(Object.keys(this.businessInfo)[0] || -1))
      }
    })
  }

  setCurrentTimezone(zone: string) {
    try {
      dayjs.tz.setDefault(zone)
      this.currentTimezone = zone
    } catch (error) {
      console.error('timezone set error')
      console.error(error)
    }
  }
  setBusinessId(businessId: number) {
    this.businessId = businessId
    localStorage.setItem('businessId', String(businessId))
  }
  resetConfig() {
    this.email = ''
    this.photo = ''
    this.username = ''
    this.hasPermissions = {}
    this.isLoading = true
    this.soupPermission = {
      status: false,
      soupUrl: ''
    }
  }
}

const commonConfig = new CommonConfig()

autorun(() => {
  const services = [taskService, queueService, reportService]
  services.forEach((item) => {
    item.serviceInstance.defaults.headers.common['Timezone'] = commonConfig.currentTimezone
    item.serviceInstance.defaults.headers.common['Region'] = commonConfig.region
    item.serviceInstance.defaults.headers.common['business-id'] = commonConfig.businessId
    item.serviceInstance.defaults.headers.common['Shopee-Baggage'] = `CID=${commonConfig.region}`
  })
})

export default commonConfig
