import React from 'react'
import { IRoute } from './types'
const Demo = React.lazy(() => import('@/pages/Demo'))

const router: IRoute = {
  label: '',
  path: '/demo',
  notUseForMenu: true,
  element: <Demo />
}

export default router
