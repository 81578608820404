import React, { ReactNode, useCallback, useMemo } from 'react'
import EmptyIllustration from '@/assets/svg/EmptyIllustration.svg'
import { ButtonType } from '@shopee/solar-design/lib/button'
import { Button } from '@shopee/solar-design'
import classNames from 'classnames'
import styles from './index.module.less'
import { loginOut } from '@/network/config'
import { commonConfig } from '@/stores'

type BtnCodeType = number | null

type TipsContainer = {
  beforeBtnText?: string
  btnText?: string
  afterBtnText?: string
  btnType?: ButtonType
  btnHref?: string
  code?: BtnCodeType
  btnOnClick?: React.MouseEventHandler<HTMLElement>
}

interface Props {
  tipsContainer?: TipsContainer
  children?: string | ReactNode
  className?: string
}

export const EVENT_CODE = {
  LOGIN_OUT: 70000006,
  SOUP_ERROR: 70000004
}

export default function NoAccessTips(props: Props) {
  const { tipsContainer, children, className = '' } = props
  const {
    beforeBtnText = "You don't have access to this portal, please apply for access via",
    btnText,
    afterBtnText = '.',
    btnType = 'link',
    btnHref = '',
    code,
    btnOnClick
  } = tipsContainer || {}

  // 根据ClickCode决定按钮点击事件，默认只刷新页面
  const handleBtnClick = useCallback(
    async (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
      e.preventDefault()
      switch (code) {
        case EVENT_CODE.LOGIN_OUT: {
          try {
            await loginOut()
            window.location.reload()
          } catch (err) {
            console.log({ err })
          }
          return
        }
        case EVENT_CODE.SOUP_ERROR: {
          window.location.href = commonConfig.soupPermission.soupUrl
          return
        }
        default: {
          window.location.reload()
          return
        }
      }
    },
    [code]
  )

  // 当传入btnOnClick时，覆盖默认的handleBtnClick事件
  const btn = useMemo(() => {
    if (btnText) {
      return (
        <Button
          type={btnType}
          href={btnHref}
          style={{ padding: '0 0 0 5px' }}
          target={btnOnClick ? undefined : '_blank'}
          onClick={btnOnClick ? btnOnClick : !btnHref ? (e) => handleBtnClick(e) : undefined}
        >
          {btnText}
        </Button>
      )
    }
    return ''
  }, [btnText, btnType, btnHref, btnOnClick, handleBtnClick])

  return (
    <div className={classNames(styles.tnsTaskCenterNoAccess, className)}>
      <img src={EmptyIllustration} alt='' />
      <div className={styles.tips}>
        {children ? (
          <>{children}</>
        ) : (
          <>
            {beforeBtnText}
            {btn}
            {afterBtnText}
          </>
        )}
      </div>
    </div>
  )
}
