import { reaction } from 'mobx'
import { tracking as trackingLib } from '@safety-lib/utils'
import { commonConfig } from '@/stores'

const globalData: Record<string, any> = {}

/** 初始化埋点 */
reaction(
  () => {
    const { isLoading, region, email, userId, businessId } = commonConfig
    return { isLoading, region, email, userId, businessId }
  },
  ({ isLoading, region, email, userId, businessId }) => {
    if (!isLoading)
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      trackingLib.init({
        env: DEPLOY_ENV || 'test',
        appId: 85,
        locale: region,
        sendImmediately: true,
        userId
      })
    globalData.email = email
    globalData.businessId = businessId
    globalData.region = region
  }
)

export const tracking = { ...trackingLib, globalData }
