import { IRoute } from './index'

// 判断用户是否有该路由的权限
export const hasRouterPermiss = (route: IRoute, permissions: Record<string, boolean>): boolean => {
  return (
    !route.permissions || // 不需要 permission
    route.permissions.every((item: any) => !!(permissions && permissions[item]))
  )
}

export const routeMap = (routes: IRoute[]) => {
  let map: { [key: string]: { label: string; canNav: boolean } } = {}
  routes.forEach((route) => {
    const { label, path, children, element } = route
    const mapPath = path.indexOf('/') === -1 ? '/' + path : path
    map[mapPath] = { label, canNav: !!element }
    if (children) {
      map = { ...map, ...routeMap(children) }
    }
  })
  return map
}

// 处理route
export const routePath = (route: string) => {
  return route.indexOf('/') === 0 ? route : '/' + route
}
