import React from 'react'
import { IRoute } from './types'
const LiveCheck = React.lazy(() => import('@/pages/LiveCheck'))

const router: IRoute = {
  label: '',
  path: '/live-check',
  notUseForMenu: true,
  element: <LiveCheck />
}

export default router
