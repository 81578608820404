import React from 'react'
import { Navigate } from 'react-router-dom'
import { IRoute } from './types'
const TemplateList = React.lazy(() => import('@/pages/templateConfig/List'))
const TemplateEditor = React.lazy(() => import('@/pages/templateConfig/EditPage'))

const router: IRoute[] = [
  {
    label: '',
    path: '/template',
    notUseForMenu: true,
    element: <Navigate to='/template/list' />
  },
  {
    label: 'Template',
    notUseForMenu: true,
    path: '/template',
    children: [
      {
        label: 'Template List',
        path: 'list',
        notUseForMenu: true,
        hiddenMenu: true,
        element: <TemplateList />
      },
      {
        label: 'Create Template',
        path: 'create',
        notUseForMenu: true,
        hiddenMenu: true,
        element: <TemplateEditor />,
        jumpAfterSwitchBiz: '/task/template/list'
      },
      {
        label: 'Template Editor',
        path: 'editor/:id',
        notUseForMenu: true,
        hiddenMenu: true,
        element: <TemplateEditor />,
        jumpAfterSwitchBiz: '/task/template/list'
      }
    ]
  }
]

export default router
