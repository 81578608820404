import { lazy } from 'react'
import { IRoute } from './types'
import { ReactComponent as AuditSvg } from '@/assets/svg/audit.svg'

const QueueList = lazy(() => import('@/pages/task/QueueList'))
const Audit = lazy(() => import('@/pages/task/Audit'))
const ViewTask = lazy(() => import('@/pages/task/ViewTask'))
const SearchTask = lazy(() => import('@/pages/task/QueueList/components/SearchTaskList'))
const QA = lazy(() => import('@/pages/task/QA'))
const ViewEmbeded = lazy(() => import('@/pages/task/ViewEmbeded'))
const Arbitration = lazy(() => import('@/pages/task/Arbitration'))

const router: IRoute[] = [
  {
    label: 'Start QC',
    path: '/task-list',
    icon: <AuditSvg />,
    element: <QueueList />
  },
  {
    label: '',
    path: '/audit',
    notUseForMenu: true,
    hiddenMenu: true,
    hiddenHeader: true,
    element: <Audit />
  },
  {
    label: 'View Task',
    path: '/view-task',
    notUseForMenu: true,
    hiddenMenu: true,
    hiddenHeader: true,
    element: <ViewTask />
  },
  {
    label: 'Search Task',
    path: '/search-task',
    hiddenHeader: true,
    notUseForMenu: true,
    element: <SearchTask />
  },
  {
    label: '',
    path: '/qa',
    notUseForMenu: true,
    hiddenMenu: true,
    hiddenHeader: true,
    element: <QA />
  },
  {
    label: 'View Task',
    path: '/view-embeded',
    notUseForMenu: true,
    hiddenMenu: true,
    hiddenHeader: true,
    element: <ViewEmbeded />
  },
  {
    label: 'Arbitration',
    path: '/arbitration',
    notUseForMenu: true,
    hiddenMenu: true,
    hiddenHeader: true,
    element: <Arbitration />
  }
]
export default router
