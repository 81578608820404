import React from 'react'
import { BrowserView, MobileView } from 'react-device-detect'
import AppLayout from './layout'
import { BrowserRouter } from 'react-router-dom'
import { ConfigProvider } from '@shopee/solar-design'
import en_US from '@shopee/solar-design/lib/locale/en_US'
import { BASE_NAME } from './constants'

function App() {
  return (
    <ConfigProvider locale={en_US}>
      <BrowserRouter basename={BASE_NAME}>
        <BrowserView renderWithFragment>
          <AppLayout />
        </BrowserView>
        <MobileView renderWithFragment>
          <p style={{ fontSize: '18px', fontWeight: 'bold' }}>Please use desktop version</p>
        </MobileView>
      </BrowserRouter>
    </ConfigProvider>
  )
}

export default App
