import React from 'react'
import { IRoute } from './types'
import { Navigate } from 'react-router-dom'
import { ReactComponent as QueueManagementSvg } from '@/assets/svg/queueManagement.svg'
const QueueBoard = React.lazy(() => import('@/pages/QueueManagement/QueueBoard'))
const QueueBoardEdit = React.lazy(() => import('@/pages/QueueManagement/QueueBoard/Edit'))
const QueueGroupBoard = React.lazy(() => import('@/pages/QueueManagement/QueueGroupBoard'))
const QueueGroupEditor = React.lazy(() => import('@/pages/QueueManagement/QueueGroupBoard/Editor'))

const router: IRoute[] = [
  {
    label: '',
    path: '/queue-management',
    element: <Navigate to='/queue-management/queue-board' />,
    notUseForMenu: true
  },
  {
    label: 'Queue Management',
    path: '/queue-management',
    icon: <QueueManagementSvg />,
    children: [
      {
        label: 'Queue Board',
        path: 'queue-board',
        element: <QueueBoard />
      },
      {
        label: 'Create Queue Board',
        path: 'queue-board/create',
        element: <QueueBoardEdit />,
        notUseForMenu: true,
        jumpAfterSwitchBiz: '/task/queue-management/queue-board'
      },
      {
        label: 'Edit Queue Board',
        path: 'queue-board/:qid/edit',
        element: <QueueBoardEdit />,
        notUseForMenu: true,
        jumpAfterSwitchBiz: '/task/queue-management/queue-board'
      },
      {
        label: 'Queue Group Board',
        path: 'queue-group-board',
        element: <QueueGroupBoard />
      },
      {
        label: '',
        path: 'queue-group-board/create',
        element: <QueueGroupEditor />,
        notUseForMenu: true,
        jumpAfterSwitchBiz: '/task/queue-management/queue-group-board'
      },
      {
        label: '',
        path: 'queue-group-board/:id/edit',
        element: <QueueGroupEditor />,
        notUseForMenu: true,
        jumpAfterSwitchBiz: '/task/queue-management/queue-group-board'
      }
    ]
  }
]

export default router
