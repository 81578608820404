import React, { useMemo } from 'react'
import { Select } from '@shopee/solar-design'
import { observer } from 'mobx-react-lite'
import { commonConfig } from '@/stores'
import { useCallback } from 'react'

// 目前只有一个地区，先这样，后期需要添加切换的onChange事件
const Timezone: React.FunctionComponent = () => {
  const { timezoneList, currentTimezone, setCurrentTimezone } = commonConfig
  const fieldNames = useMemo(() => {
    return {
      label: 'name',
      value: 'value'
    }
  }, [])
  const handleChange = useCallback(
    (zone: string) => {
      localStorage.setItem('timezone', zone)
      setCurrentTimezone(zone)
    },
    [setCurrentTimezone]
  )
  return (
    <Select
      onSelect={handleChange}
      options={timezoneList}
      defaultValue={currentTimezone}
      fieldNames={fieldNames}
      className='task-center-timezone-select'
    />
  )
}

export default observer(Timezone)
