import { lazy } from 'react'
import { IRoute } from './types'
import { ReactComponent as OverallSvg } from '@/assets/svg/overall.svg'
const Overall = lazy(() => import('@/pages/overallDashboard'))

const router: IRoute[] = [
  {
    label: 'Overall Dashboard',
    path: '/overall',
    icon: <OverallSvg />,
    element: <Overall />
  }
]

export default router
