// 获取文件后缀，例如m3u8
export function getFileTypeByUrl(url: string) {
  return url.split(/[#?]/)[0].split('.').pop()?.trim()
}

// 获取地区，例如shopee.co.id
export function getUrlRegion() {
  const host = window.location.hostname
  return host.substring(host.indexOf('shopee'))
}

export enum Env {
  Live = 'live',
  Uat = 'uat',
  Test = 'test'
}

// 获取环境
export function getEnvByHost() {
  const host = window.location.hostname
  if (host.includes('test')) {
    return Env.Test
  } else if (host.includes('uat')) {
    return Env.Uat
  } else {
    return Env.Live
  }
}
